@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?pokv3e');
  src:  url('../fonts/icomoon.eot?pokv3e#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?pokv3e') format('truetype'),
    url('../fonts/icomoon.woff?pokv3e') format('woff'),
    url('../fonts/icomoon.svg?pokv3e#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-bed-double:before {
  content: "\e900";
}
.icon-bed-single:before {
  content: "\e901";
}
.icon-identity:before {
  content: "\e902";
}
.icon-id:before {
  content: "\e903";
}
.icon-key:before {
  content: "\e904";
}
.icon-check-in:before {
  content: "\e905";
}
.icon-door:before {
  content: "\e906";
}
.icon-check-out:before {
  content: "\e907";
}
.icon-guest:before {
  content: "\e909";
}
.icon-blueprint:before {
  content: "\e90a";
}
.icon-bathroom:before {
  content: "\e90b";
}
.icon-shower:before {
  content: "\e90c";
}
.icon-terrace:before {
  content: "\e90d";
}
.icon-balcony-with-view:before {
  content: "\e90e";
}
.icon-bedrooms-with-view:before {
  content: "\e90f";
}
.icon-lounge-area-with-sunbeds:before {
  content: "\e910";
}
.icon-private-pool:before {
  content: "\e911";
}
.icon-wifi:before {
  content: "\e912";
}
.icon-shared-pool:before {
  content: "\e913";
}
.icon-tv:before {
  content: "\e914";
}
.icon-bluetooth:before {
  content: "\e915";
}
.icon-air-conditioning:before {
  content: "\e916";
}
.icon-gym:before {
  content: "\e917";
}
.icon-hot-tub:before {
  content: "\e918";
}
.icon-gas-bbq:before {
  content: "\e919";
}
.icon-dining-table:before {
  content: "\e91a";
}
.icon-bedroom:before {
  content: "\e91b";
}
.icon-equipped-kitchen:before {
  content: "\e91c";
}
.icon-concierge:before {
  content: "\e91d";
}
.icon-lounge-area:before {
  content: "\e91e";
}
.icon-swimming-pool:before {
  content: "\e91f";
}
.icon-indoor-outdoor-speakers:before {
  content: "\e920";
}
.icon-free-parking:before {
  content: "\e921";
}
.icon-infinity-pool:before {
  content: "\e922";
}
.icon-in-town:before {
  content: "\e923";
}
.icon-garden:before {
  content: "\e924";
}
.icon-sea-front:before {
  content: "\e925";
}
.icon-bed:before {
  content: "\e926";
}
.icon-bath-toiletries:before {
  content: "\e927";
}
.icon-bath-pool-towels:before {
  content: "\e928";
}
.icon-clean-linen-basket:before {
  content: "\e929";
}
.icon-bbq:before {
  content: "\e92a";
}
.icon-dinning-area:before {
  content: "\e92b";
}
