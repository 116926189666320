.leaflet-popup-content-wrapper{
    border-radius: 0;
    padding: 0;
}

.leaflet-popup-content{
    margin: 0;
}

.leaflet-popup-content .card{
    border: 0;
}

.leaflet-popup-content .card-img, .card-img-top{
    border: 0;
}

