.footer{
  #tabbar {
    --c-color: #763FEE;
    --c-background: #FFFFFF;
    --indicator-x: 34px;
    --indicator-circle-o: 1;
    --indicator-circle-y: 0px;
    -webkit-tap-highlight-color: transparent;
    border-radius: 31px;
    overflow: hidden;
    position: relative;
    background: var(--c-background);

    .indicator {
        position: absolute;
        z-index: 1;
        left: 0;
        bottom: 0;
        display: block;
        width: 68px;
        height: 72px;
        fill: var(--c-color);
        transform: translateX(var(--indicator-x));
        path {
            transform: translateY(2px);
        }
        circle {
            opacity: var(--indicator-circle-o);
            transform: translateY(var(--indicator-circle-y)) translateZ(0);
        }
    }
    ul {
        padding: 0;
        margin: 0 auto;
        list-style: none;
        width: 375px;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 1;
        li {
            --icon-y: 0px;
            --icon-s: 1;
            --icon-circle: 0px;
            &:not(:first-child) {
                margin-left: 26px;
            }
            &:not(:last-child) {
                margin-right: 26px;
            }
            &.active {
                --icon-circle: 28px;
            }
            button {
                display: block;
                width: 28px;
                height: 28px;
                padding: 0;
                margin: 0;
                cursor: pointer;
                outline: none;
                background: none;
                border: none;
                position: relative;
                -webkit-appearance: none;
                transform: translateY(var(--icon-y)) scale(var(--icon-s)) translateZ(0);
                &:before {
                    content: '';
                    position: absolute;
                    z-index: 1;
                    left: 0;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    border-radius: 50%;
                    background: var(--c-background);
                    clip-path: circle(var(--icon-circle) at 50% 100%);
                }
                i {
                    color: var(--c-color);
                    display: block;
                    overflow: visible;
                    width: 28px;
                    height: 28px;
                    &:last-child {
                        position: absolute;
                        z-index: 2;
                        top: 0;
                        left: 0;
                        clip-path: circle(var(--icon-circle) at 50% 100%);
                    }
                }
            }
        }
    }
  }
}