.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  height: 56px;
}

.App-link {
  color: #61dafb;
}

.main{
  margin-top: 56px;
}

.btn{
  border-radius: 0% !important;
  margin: 0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#myCarousel {
  width: 100%;
}
.carousel-control {
  margin-top: 20px;
}
.carousel-inner .item {
  width: 100%;
  height: 400px;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.leaflet-container{
  height: 600px; 
  width: 100%;
}

.leaflet-popup-content {
  padding: 15px;

  .btn{
    border-color: #000;
  }
}

.Container {
  position: relative;
  min-height: 300px;
  max-height: 800px;
  overflow: hidden;
  margin-bottom: 500px;
}

/* Style the video: 100% width and height to cover the entire window */
.Video {
  width: 100%;
  height: 100%;
}

/* Add some content at the bottom of the video/page */
.Content {
  position: absolute;
  top: 0;
  color:#f1f1f1;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}
.SubContent {
    text-align: center;
    padding: 10px;
  }
.SubContent p {
    color: #eeeeeead;
    font-size: 1.3rem;
}
.SubContent button {
    font-weight: bold;
    margin-top: 30px;
    color: #f1f1f1;
    border: #f1f1f1 1px solid;
    padding: 10px 5px;
    background-color: hsla(0, 0%, 0%, 0);
    font-size: 1.25rem;
    border-radius: 5px;
}
.SubContent button:hover {
    background-color: #f1f1f1;
    color: #000000c0;
}

.SubContent img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  display: block;
  margin: 15px auto;
  border: #f1f1f1 1px solid;
}

#mapid{
  z-index: 0;
}

.thumbnail-gallery {
  margin-left: auto;
  margin-right: auto;

  img{
    width: 150px;
    height: 112px;
    padding: 0;
    margin-right: auto;
    margin-left: auto;
    background-color: black;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    float: left;
  }
}

.thumbnail-gallery {
  text-align:center;

  ul{

    li{
      display: inline-block;
      *display:inline; /* ie7 fix */
      float: none;

      .pswp__img{
        max-width: none;
        object-fit: contain;
      }
    } 
  }
}

.react-calendar{
  margin: auto;

  .react-calendar__tile--now {
    background: #ffff76 !important;
    color: black !important;
    text-decoration: underline;
  }

  .react-calendar__tile--rangeEnd:not(.react-calendar__tile--hoverStart) + .react-calendar__tile:disabled{
    background: linear-gradient(to bottom right, #006edc 50%, #f0f0f0 50%) ;
  }

  .react-calendar__tile--rangeEnd:not(.react-calendar__tile--hoverStart) + .react-calendar__tile{
    background: linear-gradient(to bottom right, #006edc 50%, #ffffff 50%) ;
  }

  .react-calendar__tile:disabled + .react-calendar__tile--rangeStart{
    background: linear-gradient(to top left, #006edc 75%, #f0f0f0 50%) ;
  }

  .react-calendar__tile + .react-calendar__tile--rangeStart{
    background: linear-gradient(to top left, #006edc 75%, #ffffff 50%) ;
  }

  .react-calendar__tile + .react-calendar__tile--rangeStart{
    color: white;
  }

  .react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus {
    background-color: #e6e6e6;
  }

  .react-calendar__tile:disabled{
    background: linear-gradient(to bottom right, #ffffff 50%, #f0f0f0 50%) ;
  }

  .react-calendar__tile:disabled + .react-calendar__tile:disabled {
    background: #f0f0f0;
  }

  .react-calendar__tile:enabled:hover , .react-calendar__tile:enabled:focus{
    background: #006edc ;
  }

  .react-calendar__month-view .react-calendar__tile--active:enabled:hover, .react-calendar__month-view .react-calendar__tile--active:enabled:focus{
    background: #006edc;
  }

  .react-calendar__month-view .react-calendar__tile--rangeBothEnds{
    background: #006edc;
  }

  .react-calendar__month-view .react-calendar__tile--hoverStart{
    background: #006edc ;
  }
}

.thumbnail-gallery .slick-next{
  right: 10px; 
}

.thumbnail-gallery .slick-prev{
  left: 10px;
}

.quick-details{
  .circle-icons{
    font-size: 1.5rem;
    position: relative;
    border-radius: 5px;
    padding: 5px;
    margin: 3px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: color 0.2s ease, background-color 0.2s ease, transform 0.3s ease;
  
    &::hover {
      background-color: transparent;
      transform: rotate(90deg);
      cursor: pointer;
      box-shadow: none;
    }

    .text{
      font-size: 0.8rem;
    }
    
  }
  
  p {
    color: #999;
    font-weight: 300;
    margin-top: 20px;
  }
  
  @media (min-width: 601px) {
    .circle-icons{
      svg{
      padding: 10px;
      margin: 5px;
      font-size: 3rem;
      }
    }
  }
  
  @media (min-width: 993px) {
    .circle-icons{
      svg{
        padding: 20px;
        margin: 5px;
        font-size: 5rem;
    
        &:after {
          border-width: 3px;
          top: -3px;
          left: -3px;
        }
      }
    }
  }
}

.filters{
  .rounded-circle{
    padding: 0.2rem;
  
    svg{
      display: block;
    }
  }

  input[type="number"]{
    width: 42px;
  }
}




@import '/src/assets/css/icons.css';